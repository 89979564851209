<template>
    <div class="login">
        <div class="login-top">
            <img src="@/assets/images/login-top.png" alt="" />
        </div>
        <div class="login-form">
            <van-row class="identity-choice" justify="center" type="flex">
                <van-col span="12">
                    <van-image
                        @click="identity = 1"
                        width="100"
                        :src="
                            require(identity === 1
                                ? '@/assets/images/user-active.png'
                                : '@/assets/images/user-default.png')
                        "
                    />
                </van-col>
                <van-col span="10">
                    <van-image
                        @click="identity = 2"
                        width="100"
                        :src="
                            require(identity === 2
                                ? '@/assets/images/doctor-active.png'
                                : '@/assets/images/doctor-default.png')
                        "
                    />
                </van-col>
            </van-row>
            <van-row class="mt-10" type="flex" justify="center">
                <van-col span="10">
                    <van-button
                        block
                        size="small"
                        type="primary"
                        color="#00C3AE"
                        v-if="identity === 1"
                        @click="setIdentity"
                        >确定
                    </van-button>
                    <van-button
                        block
                        size="small"
                        type="primary"
                        color="#00C3AE"
                        v-if="identity === 2"
                        @click="toCertificationPage"
                        >{{ this.getDoctorAuditStatusCn() }}
                    </van-button>
                </van-col>
            </van-row>
        </div>
    </div>
</template>
<script>
import { getUser, setIdentity } from '@/services/user';
import { toast, toFirstPage } from '@/util';

export default {
    data() {
        return {
            identity: 1,
            doctorAuditStatus: 0,
        };
    },
    async created() {
        await this.getUser();
    },
    methods: {
        // 获取用户详情
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                return toast(message);
            }
            const { identity, doctorAuditStatus } = data;
            this.identity = identity || 1;
            this.doctorAuditStatus = doctorAuditStatus;
        },

        // 选择用户身份
        async setIdentity() {
            const { code, message } = await setIdentity(this.identity);
            if (!code) {
                return toast(message);
            }
            const res = await getUser();
            if (!res.code) {
                return toast(res.message);
            }
            toFirstPage(this.$router, res.data);
        },

        getDoctorAuditStatusCn() {
            switch (this.doctorAuditStatus) {
                case 1:
                    return '认证审核中';
                case 3:
                    return '认证通过';
                default:
                    return '去认证';
            }
        },

        toCertificationPage() {
            if (this.doctorAuditStatus === 3) {
                return this.$router.push({ path: '/doctor/home' });
            }
            this.$router.push({ path: '/doctor/my/certification' });
        },
    },
};
</script>
<style scoped>
.login {
    background: #fff;
    height: 100%;
}

.login-top img {
    width: 100%;
}

.login-form {
    background: #fff;
    position: relative;
    top: -20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    padding: 80px 35px;
}

.identity-choice .van-button {
    border: none;
    background: none;
    height: auto;
    text-align: center;
    font-size: 14px;
}

.identity-choice .van-col {
    text-align: center;
}
</style>
